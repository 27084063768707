#blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:1;
}

#blog > div.home_columns > div.column_2 > img {
    width: 273px;
    height: 206px;
}

#blog > div.home_columns > div.column_3 > a:nth-child(1) > img {
    width: 170px;
    height:224px;
}

#blog > div.home_columns > div.column_3 {
    font-size: 14px;
}

#blog > div.home_columns > div.column_3 > a:nth-child(16) > img {
    width: 216px;
    height: 162px;
}

#blog > div.home_columns > div.column_3 > img:nth-child(29) {
    width: 202px;
    height: 107px;
}

#blog > div.home_columns > div.column_3 > img:nth-child(31) {
    width: 128px;
    height: 126px;
}

#blog > div.home_columns > div.column_3 > img:nth-child(33) {
    width: 135px;
    height: 76px;
}
