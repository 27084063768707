purple_box {
    padding: 0px;
    border-width: 1px;
    border-color: #6C4687;
    border-style: solid;
    background-color: #FFFFFF;
    text-align: center;
    color: #6C4687;
    font-family: Arial, Helvetica, adobe-helvetica, Arial Narrow;
    font-size: 16px;
}

blue_back {
    padding: 0px;
    border-width: 0px;
    border-color: #000000;
    border-style: solid;
    background-color: #C9E9FA;
    color: black;
    text-align: left;
    font-family: Arial, Helvetica, adobe-helvetica, Arial Narrow;
    font-size: 13px;
}