#surgery {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:1;
}

#surgery > div.home_columns > div.column_2 > img {
    width: 273px;
    height: 206px;
}

#surgery > div.home_columns > div.column_3 > a:nth-child(1) > img {
    width: 170px;
    height:224px;
}

#surgery > div.home_columns > div.column_3 {
    font-size: 14px;
}

#surgery > div.home_columns > div.column_3 > a:nth-child(16) > img {
    width: 216px;
    height: 162px;
}

#surgery > div.home_columns > div.column_3 > img:nth-child(29) {
    width: 202px;
    height: 107px;
}

#surgery > div.home_columns > div.column_3 > img:nth-child(31) {
    width: 128px;
    height: 126px;
}

#surgery > div.home_columns > div.column_3 > img:nth-child(33) {
    width: 135px;
    height: 76px;
}

blued_box {
    color: #000066;
    padding: 5px;
    border-width: 2px;
    border-color: #000066;
    border-style: solid;
    background-color: #FFFFFF;
    text-align: center;
}

.images {
    display: flex;
    gap: 10px;
}
.images > img:first-child{
    width: 241px;
    height: 224px;
}
.images > img:nth-child(2){
    width: 190px;
    height: 224px;
}