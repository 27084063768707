whitebox {
    padding: 5px;
    border-width: 2px;
    border-color: #000066;
    border-style: solid;
    background-color: #FFFFFF;
    font-family: Arial, Helvetica, adobe-helvetica, Arial Narrow;
    font-size: 16px;
    color: black;
    text-align: center;
}

#about_doctor > div.home_columns > div.column_2_about {
    display: flex;
    align-items: center;
}