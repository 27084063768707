#testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:1;
}

#testimonials > div.home_columns > div.column_2 > img {
    width: 273px;
    height: 206px;
}

#testimonials > div.home_columns > div.column_3 > a:nth-child(1) > img {
    width: 170px;
    height:224px;
}

#testimonials > div.home_columns > div.column_3 {
    font-size: 14px;
}

#testimonials > div.home_columns > div.column_3 > a:nth-child(16) > img {
    width: 216px;
    height: 162px;
}

#testimonials > div.home_columns > div.column_3 > img:nth-child(29) {
    width: 202px;
    height: 107px;
}

#testimonials > div.home_columns > div.column_3 > img:nth-child(31) {
    width: 128px;
    height: 126px;
}

#testimonials > div.home_columns > div.column_3 > img:nth-child(33) {
    width: 135px;
    height: 76px;
}

div1 {
    padding: 5px;
    border-width: 2px;
    border-color: #23671C;
    border-style: solid;
    background-color: #FFFFFF;
    font-size: 11.7px;
    font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
    color: rgb(109, 97, 189);
}

div2 {
    padding: 5px;
    border-width: 2px;
    border-color: #000066;
    border-style: solid;
    background-color: #C7BDAE;
    font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
    color: #000066;
}

div3 {
    padding: 5px;
    border-width: 3px;
    border-color: #23671C;
    border-style: solid;
    background-color: #406AB0;
    font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
    color: white;
}

div4 {
    padding: 5px;
    border-width: 1px;
    border-color: #000000;
    border-style: solid;
    background-color: #BEC4FD;
    font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
    color: black;
}

div5 {
    padding: 5px;
    border-width: 2px;
    border-color: #000000;
    border-style: solid;
    background-color: #FFFFFF;
    color: black;
}

div6 {
    padding: 5px;
    border-width: 4px;
    border-color: #6D61BD;
    border-style: solid;
    background-color: #E8E7CE;
    font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
    color: #000066;
}

img.repeat-testimonials {
    height: 2350px;
    width: 1004px;
}