.footer {
    text-align: center;
    font-size: 12px;
    padding: 20px 0;
}

.footer > a {
    color: black;
    line-height: 150%;
    text-decoration: none;
}

.footer > a:hover{
    color: #710F4B;
}