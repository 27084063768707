#links {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:1;
}

#links > div.home_columns > div.column_2 > img {
    width: 273px;
    height: 206px;
}

#links > div.home_columns > div.column_3 > a:nth-child(1) > img {
    width: 170px;
    height:224px;
}

#links > div.home_columns > div.column_3 {
    font-size: 14px;
}

#links > div.home_columns > div.column_3 > a:nth-child(16) > img {
    width: 216px;
    height: 162px;
}

#links > div.home_columns > div.column_3 > img:nth-child(29) {
    width: 202px;
    height: 107px;
}

#links > div.home_columns > div.column_3 > img:nth-child(31) {
    width: 128px;
    height: 126px;
}

#links > div.home_columns > div.column_3 > img:nth-child(33) {
    width: 135px;
    height: 76px;
}

d1v {
    padding: 5px 10px;
    border-width: 1px;
    border-color: #000066;
    border-style: solid;
    background-color: #FFFFFF;
    font-size: 13px;
    line-height: normal;
}

green {
    color: #23671C;
    font-size: 15px;
    text-align: center;
}