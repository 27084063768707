#faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:1;
}

#faqs > div.home_columns > div.column_2 > img {
    width: 273px;
    height: 206px;
}

#faqs > div.home_columns > div.column_3 > a:nth-child(1) > img {
    width: 170px;
    height:224px;
}

#faqs > div.home_columns > div.column_3 {
    font-size: 14px;
}

#faqs > div.home_columns > div.column_3 > a:nth-child(16) > img {
    width: 216px;
    height: 162px;
}

#faqs > div.home_columns > div.column_3 > img:nth-child(29) {
    width: 202px;
    height: 107px;
}

#faqs > div.home_columns > div.column_3 > img:nth-child(31) {
    width: 128px;
    height: 126px;
}

#faqs > div.home_columns > div.column_3 > img:nth-child(33) {
    width: 135px;
    height: 76px;
}

bluebox {
    padding: 5px;
    border-width: 2px;
    border-color: #000066;
    border-style: solid;
    text-align: center;
    background-color: #FFFFFF;
}

.a_link_cosmic {
    font-family: 'Comic Sans MS', Arial, Helvetica, sans-serif;
    color: #710F4B;
    font-weight: bold;
    font-size: 14px;
}

img.repeat_faqs {
        height: 3300px;
        width: 1004px;
}