#aaaasf {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:1;
}

#aaaasf > div.home_columns > div.column_2 > img {
    width: 273px;
    height: 206px;
}

#aaaasf > div.home_columns > div.column_3 > a:nth-child(1) > img {
    width: 170px;
    height:224px;
}

#aaaasf > div.home_columns > div.column_3 {
    font-size: 14px;
}

#aaaasf > div.home_columns > div.column_3 > a:nth-child(16) > img {
    width: 216px;
    height: 162px;
}

#aaaasf > div.home_columns > div.column_3 > img:nth-child(29) {
    width: 202px;
    height: 107px;
}

#aaaasf > div.home_columns > div.column_3 > img:nth-child(31) {
    width: 128px;
    height: 126px;
}

#aaaasf > div.home_columns > div.column_3 > img:nth-child(33) {
    width: 135px;
    height: 76px;
}

trans {
    padding: 5px;
    border-width: 2px;
    border-color: #000066;
    border-style: solid;
    background-color: transparent;
    font-size: 15px;
    color: #000066;
    text-align: center;
}

#aaaasf > div.home_columns > div.column_2 > img {
    width: 279px;
    height: 266px
}

black_border {
    padding: 5px;
    border-width: 1px;
    border-color: #000000;
    border-style: solid;
    background-color: transparent;
    text-align: center;
}

img.repeat4 {
    height: 1050px;
    width: 1004px;
}